import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"

const Terms = () => {
  const data = useStaticQuery(TermsPageQuery)

  console.log("DATA", data)

  const terms = data.allContentfulTerms.edges[0].node

  console.log(terms)

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <b className="font-bold">{text}</b>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} className="underline">
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2>{children}</h2>
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData, description } = node.data.target
        return (
          <GatsbyImage image={getImage(gatsbyImageData)} alt={description} />
        )
      },
    },
  }

  return (
    <Layout>
      <Seo title="Terms & Conditions" />
      <div id="terms" className="tabs-terms">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="h2-heading">{terms.title}</h2>
            </div>
          </div>
          <div>{renderRichText(terms.content, options)}</div>
        </div>
      </div>
    </Layout>
  )
}

export default Terms

export const TermsPageQuery = graphql`
  query GetTerms {
    allContentfulTerms(limit: 1, filter: { type: { eq: "terms" } }) {
      edges {
        node {
          title
          content {
            raw
          }
        }
      }
    }
  }
`
